import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getLocale } from '../utils/functions';

/**
 * Initial page load to access browser information and route user
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const router = useRouter();

    useEffect(() => {
        sessionStorage.setItem('af_params', router.asPath);

        if (router.query?.dtt || router.asPath.includes('dtt=true')) {
            router
                .replace({
                    pathname: '/landing-page',
                    query: { lang: getLocale(router) }
                })
                .then();
        } else {
            router
                .replace({
                    pathname: '/home',
                    query: { lang: getLocale(router) }
                })
                .then();
        }
    }, [router]);

    return <nav />;
}
